:root {
  --very-dark-color: #437fc7;
  --dark-color: #6daffe;
  --light-color: #edf6ff;
  --very-light-color: #ffffff;
  --option-light: #e2f0f9;
  --option-dark: #286fb4;
}


.journal_cards {
  height: 300px;
  width: 37%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 5px 8px 11px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: rgb(225, 225, 225);
  padding: 10px;
  padding-left: 15px;
}

.journal_cards>header>a {
  display: inline-block;
  width: 100%;
  font-weight: 600;
  font-size: 1em;
  text-align: center;
  margin-bottom: 10px;
  text-decoration: none;
  color: var(--option-dark);
}

.journal_cards>header:hover {
  cursor: pointer;
  text-decoration: underline;
}

.journal_body {
  height: 90%;
  width: 100%;
  /* border: 1px solid; */
  display: flex;
  gap: 2em;
  margin-top: 5px;
}

.journal_img {
  height: 90%;
  width: 50%;

  border: 1px solid #555;
}

.journal_img>img {
  height: 100%;
  width: 100%;
}

.journal_card_links {
  width: 45%;
  height: 90%;
  display: flex;
  /* border: 1px solid; */
  flex-direction: column;
  justify-content: space-around;
  /* border: 1px solid; */
}
#Comming_soon{
  pointer-events: none;
opacity: 0.6;
z-index: 10;
text-decoration: underline;
}
.link {
  margin: 10px 0;
}

.link>span {
  font-weight: 600;
  text-decoration: underline;
  font-style: italic;
}

#out_paper_link {
  font-weight: 600
}

#out_paper_link:hover {
  color: var(--very-dark-color);
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
}

.journal_card_visit>a {
  color: var(--very-dark-color);
}

@media screen and (max-width: 679px) {
  .journal_cards {
    width: 66%;
  }
}

@media screen and (max-width: 385px) {
  .journal_card_link {
    display: none;
  }

  .journal_body {
    flex-direction: column;
    gap: 0px;
  }

  .journal_card_links {
    width: 99%;
  }

  .journal_cards>header>a {
    width: 98%;
  }

  .journal_cards>header {
    width: 99%;
  }

  .journal_img {
    width: 83%;
    height: 65%;
  }
}