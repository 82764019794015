:root {
  --layout-color: #36a1d4;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.Main-Container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: white;
  object-fit: cover;
  margin-top: 6px;
 
}
.maincontainer_authors>span{
  display: block;

  font-size: larger;
  font-weight: bolder;
font-size:3rem;
width: 100%;
text-align: center;

}

.maincontainer_authors{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: auto;
  /* border: 1px solid red; */
 
}
.authors{
  display: flex;
 flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 90%;
  width: 80%;
  gap: 2em;
  margin-bottom: 2em;
  margin-top: 2em;

}
   .journals_card_container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    }



.landing_searchcontainer{
  width: 70%;
  height: 80%;
  /* border:1px solid; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

    /* border: 1px solid; */

}
.landing_searchcontainer>span{
  font-size: 2rem;
  font-weight:900;
}

.maincontainer_searchtool{
  width: 90%;
  height: 80%;
border: 0.5px solid #AAA8A8;
border-radius: 5px;
padding: 1em;
}




.typewriter-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 30vh;
  align-items: center;
  padding-bottom: 3rem;
}
.Typewriter {
  text-shadow: 0 4px 8px rgba(16, 15, 15, 0.8);
  font-size: 5rem;
  color: whitesmoke;
  text-transform: uppercase;
  font-family: serif;
}



.highligtedworks{

  height: 38%;
  display: flex;
  flex-direction: column;
  /* justify-content: start; */
  padding: 8px 0;

}


  .line{
    width: 36%;
      height: 5px;
     background:  linear-gradient(to right, rgba(67, 127, 199, 1), rgba(67, 127, 199, 0));
  
  }

  .highligtedworks_cards_container{
    /* border: 1px solid; */
    height: 69%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
  
@media screen and (max-width: 679px) {
  .journals_card_container{
    flex-direction: column;
    align-items: center;
    gap:2rem
  }
}