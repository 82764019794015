:root {
    --very-dark-color: #437fc7;
    --dark-color: #6daffe;
    --light-color: #edf6ff;
    --very-light-color: #ffffff;
    --option-light: #e2f0f9;
    --option-dark: #286fb4;
    --list-variation:#f5f5f5
  }
  .editorialBoard-MainContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 3rem;
    gap: 2rem;
   
  }
  .editorialBoard-mainHeading{
  width: 70%;
  height: 3rem;
  margin: auto;
  }
  .editorialBoard-mainHeading > span{
    font-size: xx-large;
    font-weight: 700;
    color: #286fb4;
    text-decoration: underline;
  }
  .editorialBoard-Container{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 70%;
    height: 15rem;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    box-shadow: 0.4px 0.4px 5px 0px rgba(0, 0, 0, 0.5);
  }
  
  .tb-dropdown-handle-container{
    width: 100%;
    height: 8rem;
    padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  cursor: pointer;
  
}
.tb-dropdown-handle-container:nth-of-type(2n){
   background-color:var(--list-variation ); 
  
}
.tb-paper-container {
    width: 100%;
    height: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.5em 0;

  }
  
 .tbfilters{
    width: 30%;
    height: 100vh;
    background-color:var(--very-dark-color) ;
 }


