:root {
    --very-dark-color: #437fc7;
    --dark-color: #6daffe;
    --light-color: #edf6ff;
    --very-light-color: #ffffff;
    --option-light: #e2f0f9;
    --option-dark: #286fb4;
  }


.tb_content_issue_paper{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 129vh;
    margin-bottom: 10px;

}
.issueheader{
    padding: 4px 0px;
width: 77%;
display: flex;
}
  .issue_Name_container>div{
    color: var(--option-dark);
width: 90%;
padding: 3px;
text-decoration: underline;
font-size: 1.9rem;
/* font-style: italic; */
font-weight: 700;
  }
.TbMainLayout_header{
  margin-top: 6px;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5)
}  
 .TbMainLayout_img_container>img   {
        box-shadow: 0 0 3px;
    }
.tb_content_issue_paper>header{
    margin-top: 1rem;
    width: 77%;
    background-color: white;
    height: 3rem;
   padding-left: 2%;
   box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5)
}  
.tb_content_issue_paper>header>span{
    font-size: 1.9rem;
font-weight: 600;
/* letter-spacing: 1px; */
word-spacing: 4px;
color: var(--option-dark);
}
.tb_content_issue_paper>body {
  margin-top: 1rem;
  width: 77%;
  background-color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer and Edge */
  padding-left: 2px;
}

.tb_content_issue_paper>body::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

/* wejfnjwenkwjnef */