:root {
    --very-dark-color: #437fc7;
    --dark-color: #6daffe;
    --light-color: #edf6ff;
    --very-light-color: #ffffff;
    --option-light: #e2f0f9;
    --option-dark: #286fb4;
    --list-variation:#f5f5f5
  }
  .tbContent_main-container{
    display: flex;
    flex-direction: column;
    width: 70%;
    height: fit-content;
    /* background-color: yellow; */
    justify-content: center;
    align-items: center;
    /* border: 1px solid var(--very-dark-color); */
    border-radius: 4px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  }
  
  .tb-dropdown-handle-container{
    width: 100%;
    height: 8rem;
    padding-top: 1rem;
  padding-bottom: 1rem;
  /* border-radius: 5px; */
  position: relative;
  cursor: pointer;
  /* background-color: rgb(99, 146, 177); */
  
}
.tb-dropdown-handle-container:nth-of-type(2n){
   background-color:var(--list-variation ); 
  /* background-color:var(--dark-color ); */
/*  background: linear-gradient(to right, #ffffff, #286fb4);

  background: -webkit-linear-gradient(left, #ffffff, #286fb4);

  background: -moz-linear-gradient(left, #ffffff, #286fb4);

  background: -o-linear-gradient(left, #ffffff, #286fb4);*/
}
.tb-paper-container {
    /* border-bottom: 2px solid gray; */
    width: 100%;
    height: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.5em 0;
    /* background-color: rgb(37, 163, 96); */

  }
  /* .tb-paper-container:hover {
    border: 1px solid #c3deff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  } */
 .tbfilters{
    width: 30%;
    height: 100vh;
    background-color:var(--very-dark-color) ;
 }


