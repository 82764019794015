
.first-div {
  width: 100%;
  display: flex;
 gap: 1rem;
  align-items: center;
  /* margin-bottom: 0px; */
  background-color: #437FC7;
  padding: 10px;
  /* border-radius: 8px; */
  font-weight: bold;
  /* margin-top: 1px; */
  padding: 1.3em 0px;
  height: 60px;
  }
  
  .navbar_logocontainer{
    width: auto;
  }
  .navbar_logocontainer>a>img{
    width: 60px;
  }
  .navbar_logocontainer>a>img:hover{
    cursor: pointer;
  }
  .navbar_logo{

width: auto;
color: white;
font-weight: 800;
font-size: 2.2rem;
  }
  
  .left-links button {
    background-color: transparent;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; /* Hover transition */
    border-right: 1px solid #666; /* Separator between buttons */
    font-weight: bold;
  }
  
  .left-links button:last-child {
    border-right: none; /* Remove separator for the last button */
  
  }
  
  .left-links button:hover {
    background-color: #286FB4 /* Hover background color */
  }
  
  .right-links button {
    background-color: transparent;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; /* Hover transition */
    border-left: 1px solid #666; /* Separator between buttons */
    font-weight: bold;
  }
  
  .right-links button:first-child {
    border-left: none; /* Remove separator for the first button */
  }
  
  .right-links button:hover {
    background-color: #286FB4 ; /* Hover background color */
  }