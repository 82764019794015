.DisplayJournalsContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.DisplayJournalsMainContent{
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    gap: 3px;
}
.DisplayJournalsMainContentTitleHead{
    width: 100%;
    display: inline;
    text-align: center;
    justify-content: center;
    align-items: flex-end;
    font-size: xx-large;
    font-weight: 700;
    color: #286fb4;
    font-style: italic;
    text-decoration: underline;
}
.DisplayJournalsMainContentTitleHeadSpan{
    font-size: large;
    margin-left: 10px;
    padding-bottom: 5px;
    font-style: none;
    text-decoration: none;
}
.DisplayJournalsMainContentLogo{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 25px;
}
.DisplayJournalsMainContentLogoImg{
    width: 150px;
    height: 200px;
}
.DisplayJournalsMainContentLinks{

    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-left: 20px;
    flex-direction: column;
}
.DisplayJournalsMainContentFirstDiv{
    font-size: larger;
    margin-bottom: 8px;
}
.DisplayJournalsMainContentBodyHead{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: x-large;
    margin-top: 20px;
    color: #286fb4;
}
.DisplayJournalsMainContentBodyHeadPara{
    font-size: small;
    width: 95%;
    margin-top: 7px;
    text-align: justify;
}
.DisplayJournalsMainContentBodyHeadParaUl{
    margin-top: 0px;
}
.DisplayJournalsMainContentBodyHeadParaUl li{
    list-style: circle;

}


#Copyrightinfo{
    color:#286fb4;
    text-decoration: underline;
}

#Copyrightinfo:hover{
    cursor: pointer;
}
#Editorial_board:hover{
cursor: pointer;
}




.issn_footer_details>span{
    color: black;
font-size: 17px;
font-weight: 400;
}