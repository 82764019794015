

.navbar {
  
  color: white;
  /* display: grid;
  grid-template-rows: 0fr 0fr 2fr 2fr 4fr 2fr; */
  display: flex;
  flex-direction: column;
  align-items: center;
justify-content: space-between;
  /* align-items: center; */
  padding: 10px;
  height: auto;
  /* border: 1px solid red; */
  background: linear-gradient(rgba(67, 127, 199 ),rgba(51, 223, 68, 18%));
}


.second-div,
.third-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.first-div {
  background-color:#437FC7 ;/* Change background color of the first div */
  padding: 10px;
  /* border-radius: 8px; */
  font-weight: bold;
}

.left-links button {
  background-color: transparent;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Hover transition */
  border-right: 1px solid #666; /* Separator between buttons */
  font-weight: bold;
}

.left-links button:last-child {
  border-right: none; /* Remove separator for the last button */

}

.left-links button:hover {
  background-color: #286FB4 /* Hover background color */
}

.right-links button {
  background-color: transparent;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Hover transition */
  border-left: 1px solid #666; /* Separator between buttons */
  font-weight: bold;
}

.right-links button:first-child {
  border-left: none; /* Remove separator for the first button */
}

.right-links button:hover {
  background-color: #286FB4 ; /* Hover background color */
}

.third-div input[type="text"] {
  padding: 8px;
  border-radius: 4px;
  border: none;
  width: 70%;
}

.third-div .search-button {
  padding: 8px 16px;
}

.second-div {
  display: flex;
  justify-content: center;
}

.second-div button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Hover transition */
}

.second-div button:hover {
  background-color: #0056b3; /* Hover background color */
}

.company_name{
  display: flex;
  
  /* justify-content: center; */
  align-items: center;
  width: 90%;
  /* border: 1px solid red; */
  height: -moz-fit-content;
  height: fit-content;
  padding-left: 25%;



}
.company_name>div{
  display: flex;
flex-direction: row;
align-items: center;
}
.company_name>div>span{
  font-size: 4rem;
  /* text-shadow: 2px 2px 18px black */
  filter: drop-shadow(6px 0px 7px rgba(0, 0, 0, 0.5));
  font-weight: bolder;
}
.company_name>div>img{
  height: 8rem;
}












.third-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-bar {
  display: flex;
align-items: center;
/* border: 1px solid white; */
border-radius: 20px;
/* padding: 5px 10px; */
width: 51%;
background-color: white;

}

.search-bar input[type="text"] {
  border: none;
  background: none;
  color: black;
  width: 100%;
  outline: none;
}

.search-bar input[type="text"]::placeholder {
  color:black;
}

.search-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Hover transition */
  margin-left: 10px;
  border-radius: 20px !important;
}

.search-button:hover {
  background-color: #0056b3; /* Hover background color */
}

.third-div {
  margin-top: 20px; /* Increase the spacing between the second and third divs */
}




.search-bar input[type="text"]::placeholder {
  color:black;
}

.search-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Hover transition */
  margin-left: 10px;
}

.search-button:hover {
  background-color: #0056b3; /* Hover background color */
}

.search-button i {
  font-size: 18px;
}



.landingpage_webinfo{
  width: 80%;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.3rem;
  margin: 2rem 0;
}
.landingpage_downmenu{
  /* position: relative; */
    /* top: 8rem; */
    height: 8%;
    width: 80%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;

}
.landingpage_downmenu>svg   {
      height: 39px;
    width: 34px;
    animation: updown 2s infinite ;
    transition: transform 0.5s ease-in-out;
    }
    .landingpage_downmenu>svg :hover{
      cursor: pointer;
    }
    @keyframes updown {
     
        0% {
          transform: translateY(0);
        }
       
        50%{
          transform: translateY(-10px)
        }
        100% {
          transform: translateY(0);
        }
    
      
    }