:root {
    --very-dark-color: #437fc7;
    --dark-color: #6daffe;
    --light-color: #edf6ff;
    --very-light-color: #ffffff;
    --option-light: #e2f0f9;
    --option-dark: #286fb4;
  }

  .tbPaperContainer {
    margin: 4px;
    display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 2rem;
  padding-left: 1rem;
  }
.tbPaperContainer:hover{
    cursor: pointer;
    
}

.tbpaper {
    width: 70%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content:center;
    margin: 3px;
    /* padding-left: 1rem; */
    /* gap: 1.3rem; */
  /* background-color: green; */
}


.tbheading{
    max-height: calc(50%);
      /* color: #286fb4; */
    display: flex;
    font-size: x-large;
   font-weight: 400;
} 

@media screen and (max-width: 569px) {
  /* CSS rules for screens with a maximum width of 427px */
  /* You can put your styles here */
  .tbpaper .tbheading{
    /* text-align: center; */
    /* color: var(--option-dark); */
    font-size:small;
    width: 100%;
    /* padding: 3px; */
   
  }
  
}