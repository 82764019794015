
.Tb_content_papercontainer{

    width: 99.9%;
    height: fit-content;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
    padding: 10px;
    margin-top: 6px;
}   
.Tb_content_papercontainer>header{
    font-weight: bolder;
    color: color(display-p3 0.1647 0.3922 0.5843);
    font-size: 1.4rem;
  
}
.Tb_content_papercontainer>header:hover{
    text-decoration: underline;
    cursor: pointer;
}
.Tb_content_papercontainer>p{
     margin-top: 11px;
    /* color: #437fc7; */
    /* font-size: 20px;
font-weight: 500;  */
}
.Tb_content_papercontainer>span{
    display: block;
    width: 100%;
}
   
.publication_info_tbcontent{
    margin-top: 11px;
    display: flex;
    flex-direction: column;
    font-size: 19px;
    font-weight: 500;
    justify-content: center;
    gap: 3px;
}


.tb_content_paper_setting{
    margin-top:11px;
    display: flex;
    gap: 1rem;
    align-items: center;
}
.tb_content_paper_setting>span{
    display: flex;
    align-items: center;
    font-size: 20px;



}
.tb_content_paper_setting>span:hover{
    cursor: pointer;
    text-decoration: underline;
}
#pdf_btn{
    border-radius: 3px;
    padding: 0 10px;
    font-weight: 700;
}
#abstract>svg{
    color: #1c78e9;
}
#pdf_btn>svg{
height: 30px;
width: 30px;
font-weight: 700;
}
#doi_link{
    color: #1c6ebd;
font-weight: 600;
}
#doi_link:hover{
    cursor: pointer;
    text-decoration: underline;
}
.papaer_container_line{
    margin-top: 11px;
border: 0.0000001px solid rgb(222, 222, 222);
}
.abstractDivSmallPaper{
    font-size: small;
    text-align: justify;
    margin-top: 10px;
    margin-bottom: 20px;
}