.normal-btn {
  background-color: var(--very-dark-color);
  width: 6rem;
  height: 2.5rem;
  border-radius: 5px;
  border: 1px solid var(--very-dark-color);
  color: var(--light-color);
  cursor: pointer;
  margin: 0.2rem;
}

.stl{
  width: 150px;
}

.disabled{
  background-color: #286eb4ad;
  border: 1px solid #286eb41b;
}

.stl1{
  width: 120px;
}
