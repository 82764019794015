:root {
  --very-dark-color: #437fc7;
  --dark-color: #6daffe;
  --light-color: #edf6ff;
  --very-light-color: #ffffff;
  --option-light: #e2f0f9;
  --option-dark: #286fb4;
  --arjun-dark: color(display-p3 0.1647 0.3922 0.5843);
  --heading-color: #286fb4;
}

.TbMainLayoutMainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  /* background-color: var(--light-color); */
}

.TbMainLayout_heading {
  margin-top: 2rem;
  width: 70%;
  min-height: fit-content;
  margin-bottom: 1.5rem;
  /* background-color: green; */
}
.TbMainLayout_BookName_container {
  display: flex;
  /* align-items: flex-start; */
  width: 100%;
  min-height: fit-content;
  justify-content: center;
  margin-bottom: 2rem;
}
.TbMainLayout_img_container {
  width: 100%;
  height: 40vh;
  display: flex;
  /* background-color: rgb(230, 169, 14); */
}
.TbMainLayout_img {
  margin-top: 10px;
  width: 170px;
  height: 200px;
  margin-right: 10px;
  /* background-color: rgb(116, 113, 103); */
}

.TbMainLayoutMainContainer .TbMainLayout_BookName {
  text-align: center;
  color: var(--option-dark);
  width: 100%;
  padding: 3px;
  text-decoration: underline;
  font-size: xx-large;
  font-style: italic;
  font-weight: 700;
}
.TbMainLayout_BookName_btn {
  width: inherit;
  height: 200px;
  display: flex;
  /* background-color: yellow; */
  align-items: center;
  flex-wrap: wrap;
}
.TbMainLayout_footer {
  width: 70%;
  height: fit-content;
  /* background-color: yellow; */
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.TbMainLayout_footer_body {
  font-size: small;
  width: 100%;
  margin-top: 7px;
  text-align: justify;
}

/* Media query */
@media screen and (max-width: 1034px) {
  /* CSS rules for screens with a maximum width of 427px */
  /* You can put your styles here */
  .TbMainLayout_BookName_btn {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
@media screen and (max-width: 569px) {
  /* CSS rules for screens with a maximum width of 427px */
  /* You can put your styles here */
  .TbMainLayoutMainContainer .TbMainLayout_BookName {
    text-align: center;
    color: var(--option-dark);
    font-size: x-large;
    width: 100%;
    padding: 3px;
    text-decoration: underline;
    font-style: italic;
    font-weight: 700;
  }
}
